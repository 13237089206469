.cam-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cam-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    position: relative;
}

.cam {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    transform: rotateY(0deg);
}

.cam180 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    transform: rotateY(180deg);
}

.canvas {
    display: none;
}
