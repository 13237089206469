$media-w-xs: 576px;
$media-w-sm: 768px;
$media-w-md: 992px;
$media-w-lg: 1200px;

$media-h-xs: 360px;
$media-h-sm: 720px;
$media-h-md: 1080px;
$media-h-lg: 1150px;

/* general condition */
$spaceamounts: (
  15
); // to include the pixel amounts you need.

@each $space in $spaceamounts {
  .custom-rounded-#{$space} {
    border-radius: #{$space}px !important;
  }
}

/* margin and padding */
$spaceamounts: (
  0,
  5,
  10,
  15,
  16,
  20,
  25,
  28,
  30,
  40,
  50,
  60,
  64,
  80,
  85,
  90,
  100,
  133
); // to include the pixel amounts you need.
$sides: (
  top,
  bottom,
  left,
  right
);

@each $space in $spaceamounts {
  @each $side in $sides {
    .custom-m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;

      @if $space >=40 {

        @media screen and (max-width: 1367px),
        screen and (max-height: 850px) {
          margin-#{$side}: #{calc($space - 20)}px !important;
        }
      }

      @else if $space < 40 {
        @if $space >=15 {
          @media screen and (max-width: 900px) {
            margin-#{$side}: #{calc($space - 10)}px !important;
          }
        }
      }
    }

    .custom-p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;

      @media screen and (max-width: 1367px),
      screen and (max-height: 850px) {
        padding-#{$side}: #{calc($space - 20)}px !important;
      }
    }
  }
}

/* container position style */
@mixin centre($axis: "both") {
  position: absolute;

  @if $axis=="y" {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

    @media (max-height: 900px) {
      top: 33%;
      -webkit-transform: translateY(-25%);
      -moz-transform: translateY(-25%);
      -ms-transform: translateY(-25%);
      -o-transform: translateY(-25%);
      transform: translateY(-25%);
    }
  }

  @if $axis=="x" {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  @if $axis=="both" {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.centered-y {
  @include centre(y);
}

.centered-x {
  @include centre(x);
}

.centered-both {
  @include centre;
}

/* container general style */

@mixin make-container($padding-x: $container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}

@mixin make-footer-container($padding-y: $container-padding-y) {
  width: 100%;
  padding-top: $padding-y;
  padding-bottom: $padding-y;
  margin-right: auto;
  margin-left: auto;
}

@mixin reset-left-right-padding() {
  padding-right: 0;
  padding-left: 0;
}

@mixin reset-top-bottom-padding() {
  padding-top: 0;
  padding-bottom: 0;
}

@mixin create-container($width: $container-width) {
  width: $width;
  margin: 0 auto;
}

/* general mixin */

@mixin rounded($border) {
  -webkit-border-radius: $border;
  -moz-border-radius: $border;
  border-radius: $border;
}

@mixin shadow($style) {
  -webkit-box-shadow: $style;
  -moz-box-shadow: $style;
  box-shadow: $style;
}

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}

/* input form width logic */
$width-map: (
  120,
  180,
  200,
  236,
  280,
  300,
  305,
  320,
  354,
  400,
  420,
  445,
  470,
  480,
  500,
  558
);

@each $width in $width-map {
  .width-#{$width} {
    width: #{$width}px;
    margin: 0 auto;
    position: relative;

    /*  @include media-breakpoint-down(lg) {
            width: calc(#{$width}px - 100px);
        }

        @include media-breakpoint-down(md) {
            width: calc(#{$width}px - 40px);
        } */
  }
}

.text-underline {
  text-decoration: underline;
}

.color-grey {
  color: $grey !important;
}

.color-blue {
  color: $blue !important;
}

.color-black {
  color: $black1 !important;
}

.color-white {
  color: $white !important;
}

.background-lilla {
  background-color: $lilla;
}

.background-light {
  background: var(--light-background);
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.align-items-center {
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w-100 {
  width: 100% !important;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-right {
  margin-right: 0;
}

.margin-left-auto {
  margin-left: auto;
}