.shimmer-text {
  margin-bottom: 30px;

  &-line {
    width: 100%;
    height: 8px;
    border-radius: 10px;

    &:first-child {
      width: 100% !important;
    }

    &:last-child {
      width: 40%;
    }
  }
}
