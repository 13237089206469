.header-container {
  header {
    height: $header-height;
    padding: 1.25rem 17vw;
    background: var(--light-background);
    box-shadow: $shadow-1;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1512px) {
      padding: 1.25rem 11vw;
    }

    .header-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mobile-logo {
      display: none;
    }

    .az-chat {
      .go-back-button {
        float: right;
        margin-top: 8px;
        margin-left: 10px;

        .chatbot-img {
          width: 30px;
        }
      }
    }
  }
}

.footer-container {
  z-index: 9;

  footer {
    height: $footer-height;
    padding: 1.25rem 0;
    background: var(--light-background);
    display: flex;
    justify-content: center;
    align-items: center;

    .footer-item,
    .footer-item>* {
      @extend .text-12;
    }

    .footer-item {
      position: relative;

      &:not(:last-child) {
        padding-right: 21px;

        &::after {
          content: "";
          width: 1px;
          height: 15px;
          position: absolute;
          background-color: $grey;
          margin-left: 10px;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .header-container {
    header {
      box-shadow: none;
      height: 108px;
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-column-gap: 20px;
      justify-items: center;

      .logo-wrapper {
        grid-column-start: 2;
      }

      .header-item {
        margin-left: auto;
      }

      .mobile-logo {
        display: block;
      }

      .desktop-logo {
        display: none;
      }

      .logo-wrapper {
        background: $light-blue;
        box-shadow: $shadow-2;
        border-radius: 100px;
        width: 68px;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

// .essential-area {
//   .header-container {
//     header {
//       .logo-wrapper {
//         animation-name: logo-animation;
//         animation-duration: 1s;
//         animation-delay: 2s;
//         animation-timing-function: ease-out;
//         animation-fill-mode: forwards;
//       }

//       @keyframes logo-animation {
//         0% {
//           top: 50%;
//           transform: translateY(200px);
//         }
//         25% {
//           top: 40%;
//           transform: translateY(150px);
//         }
//         50% {
//           top: 30%;
//           transform: translateY(100px);
//         }
//         100% {
//           top: 00%;
//           transform: translateY(0px);
//         }
//       }
//     }
//   }
//   .page-content {
//     position: absolute;
//     visibility: hidden;
//     animation-name: page-content-animation;
//     animation-duration: 1s;
//     animation-delay: 2s;
//     animation-timing-function: ease-out;
//     animation-fill-mode: forwards;
//   }

//   @keyframes page-content-animation {
//     0% {
//       top: 60%;
//       transform: translateY(220px);
//       visibility: visible;
//     }
//     25% {
//       top: 40%;
//       transform: translateY(180px);
//       visibility: visible;
//     }
//     50% {
//       top: 30%;
//       transform: translateY(150px);
//       visibility: visible;
//     }
//     100% {
//       top: 10%;
//       transform: translateY(0px);
//       visibility: visible;
//     }
//   }
// }