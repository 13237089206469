.shimmer-card {
  border-radius: 8px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: 100%;

  &:not(:first-child) {
    margin-top: 30px;
  }

  &--content-center {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    * {
      width: 100%;
      margin: auto;
    }
  }
}
