$header-height: 75px;
$mobile-header-height: 108px;
$footer-height: 62px;

//COLORS
$light-ice-background: rgba(67, 44, 210, 0.25);
$light-background: linear-gradient(90deg, #f1f8ff 2%, #f5faff 100%);
$blue-gradient: linear-gradient(94.94deg, #a347ff 0%, #432cd2 100%);
$shadow-1: -5px -5px 10px #ffffff, 2px 2px 10px rgba(199, 218, 255, 0.9),
  inset 1px 1px 2px rgba(255, 255, 255, 0.3),
  inset -1px -1px 2px rgba(199, 218, 255, 0.5);
$shadow-2: 1px 1px 2px rgba(255, 255, 255, 0.3),
  0px -1px 4px rgba(169, 166, 169, 0.2),
  inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 3px #c7daff;
$shadow-3: 0px -1px 2px rgba(169, 166, 169, 0.2),
  1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 1px #ffffff,
  inset 1px 1px 6px rgba(0, 0, 0, 0.5);
$shadow-4: inset 2px 2px 5px rgba(86, 86, 86, 0.3),
  inset -2px -2px 5px rgba(255, 255, 255, 0.3);
$shadow-5: 2px 2px 10px rgba(199, 218, 255, 0.9),
  inset 1px 1px 2px rgba(255, 255, 255, 0.3),
  inset -1px -1px 2px rgba(199, 218, 255, 0.5);
$shadow-separator: inset 1px 1px 2px #cfe6ff, inset -2px -2px 4px #f0f7ff,
  inset 2px 2px 3px rgba(169, 166, 169, 0.2);
$shadow-button: inset 1px 1px 2px rgba(255, 255, 255, 0.3),
  inset -1px -1px 2px rgba(199, 218, 255, 0.5);
$light-blue: #f0f7ff;
$light-blue2: #f8fbff;
$grey: #777777;
$grey2: #494949;
$light-grey: #f3f3f3;
$lilla: #eeefff;
$blue: #432cd2;
$black1: #212121;
$black06: rgba(0, 0, 0, 0.6);
$white: #fff;
$green: #2ec057;
$red: #ff0077;
$black: #000000;
