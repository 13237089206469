$base-height: 8px;

$thumb-height: 40px;
$thumb-center-color-height: 17px;
$thumb-color: #f0f7ff;
$thumb-shadow: -5px -5px 10px #ffffff, 5px 5px 13px #cacaca,
  inset 1px 1px 2px rgba(255, 255, 255, 0.3),
  inset -1px -1px 2px rgba(194, 190, 194, 0.5);
$thumb-radius: 50%;
$input-radius: 10px;
.range-input-container {
  width: 100%;
  .range-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    height: 48px;
    font-size: 12px;
    box-shadow: 1px 1px 2px rgb(255 255 255 / 30%),
      0px -1px 2px rgb(169 166 169 / 20%),
      inset -2px 2px 4px rgb(169 166 169 / 20%),
      inset -2px -2px 4px rgb(255 255 255 / 90%), inset 2px 2px 5px #d9e6ff;
    border-radius: 100px;
    align-items: center;
    padding: 20px;
    .range-value-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      .title-value span {
        margin-right: 15px;
      }
      input[type="number"] {
        background: no-repeat;
        border: none;
        cursor: pointer;
      }
    }
    .title-title {
      font-weight: bold;
      color: #212121;
    }
  }
  .input-container {
    position: relative;
    input[type="range"] {
      width: 100%;
      -webkit-appearance: none;
      width: 100%;
      height: $base-height;
      background: transparent;
      outline: none;
      cursor: pointer;
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: $input-radius;
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: $thumb-height;
        height: $thumb-height;
        border-radius: $thumb-radius;
        background-color: transparent;
        // box-shadow: $thumb-shadow;
        cursor: pointer;
      }
      &::-moz-range-thumb {
        width: $thumb-height;
        height: $thumb-height;
        border-radius: $thumb-radius;
        background-color: transparent;
        box-shadow: $thumb-shadow;
        cursor: pointer;
      }
    }
    .range-part-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      position: absolute;
      top: 0;
      left: 0;
      height: $base-height;
      width: 100%;
      border-radius: $input-radius;
      overflow: hidden;
      .range-part {
        height: inherit;
      }
    }
    .color-center-container-h {
      width: calc(100% - 40px);
      height: 0px;
      top: 0;
      position: relative;
      .color-center-container {
        width: $thumb-height;
        height: $thumb-height;
        border-radius: $thumb-radius;
        background-color: $thumb-color;
        box-shadow: $thumb-shadow;
        transform: translate(0px, -50%);
        z-index: 1;
        position: absolute;
        .thumb-center-color {
          width: $thumb-center-color-height;
          height: $thumb-center-color-height;
          position: absolute;
          border-radius: 50%;
          // transform: translate(-50%, 75%);
          transform: translate(12px, 12px);
        }
      }
    }
  }
}
