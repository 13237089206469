@import "./components/shimmer";
@import "./state";

.shimmer {
  &-hr {
    border-color: #969797;
  }

  &-row {
    display: flex;
    margin: 0 -15px;

    .shimmer-col,
    [class*="shimmer-col-"] {
      padding-left: 15px;
      padding-right: 15px;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
  }
}
