@font-face {
    font-family: 'Manrope-ExtraLight';
    src: url('./fonts/Manrope/Manrope-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Light';
    src: url('./fonts/Manrope/Manrope-Light.ttf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Regular';
    src: url('./fonts/Manrope/Manrope-Regular.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Medium';
    src: url('./fonts/Manrope/Manrope-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-SemiBold';
    src: url('./fonts/Manrope/Manrope-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Bold';
    src: url('./fonts/Manrope/Manrope-Bold.ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-ExtraBold';
    src: url('./fonts/Manrope/Manrope-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

html,
body {
    font-family: 'Manrope-Regular', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 25px;
}

@mixin text($fontFamily, $lineHeight, $size) {
    font-family: $fontFamily;
    line-height: $lineHeight !important;
    font-size: $size;
    font-weight: normal;
}

.bold {
    font-family: 'Manrope-Bold' !important;
    font-weight: 700;
}

.semibold {
    font-family: 'Manrope-SemiBold' !important;
    font-weight: 600;
}


.blue {
    color: $blue;
}

.black {
    color: $black;
}

.text-underline {
    text-decoration-line: underline;
    text-decoration-thickness: 0.5px;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-12 {
    @include text('Manrope-Regular', 17px, 0.79rem);
}

.text-14 {
    @include text('Manrope-Regular', 23px, 0.889rem);
}

.text-16 {
    @include text('Manrope-Regular', 23px, 1rem);
}

.text-18 {
    @include text('Manrope-Regular', 25px, 1.125rem);
}

.text-25 {
    @include text('Manrope-Regular', 35px, 1.602rem);
}

.text-30 {
    @include text('Manrope-Regular', 40px, 1.8rem);
}

.text-32 {
    @include text('Manrope-Regular', 44px, 2rem);
}

.text-36 {
    @include text('Manrope-Regular', 50px, 2.281rem);
}

@media screen and (max-width: 1300px) and (min-width: 900px) {
    .text-12 {
        @include text('Manrope-Regular', 17px, 0.6rem);
    }

    .text-14 {
        @include text('Manrope-Regular', 23px, 0.7rem);
    }

    .text-16 {
        @include text('Manrope-Regular', 23px, 0.8rem);
    }

    .text-18 {
        @include text('Manrope-Regular', 25px, 1rem);
    }

    .text-25 {
        @include text('Manrope-Regular', 35px, 1.3rem);
    }

    .text-28 {
        @include text('Manrope-Regular', 37px, 1.5rem);
    }

    .text-30 {
        @include text('Manrope-Regular', 40px, 1.6rem);
    }

    .text-32 {
        @include text('Manrope-Regular', 44px, 1.8rem);
    }

    .text-36 {
        @include text('Manrope-Regular', 50px, 2rem);
    }
}

@media screen and (max-width: 900px) {
    .text-32 {
        @include text('Manrope-Regular', 44px, 1.8rem);
    }

    .text-28 {
        @include text('Manrope-Regular', 37px, 1.5rem);
    }

    .text-30 {
        @include text('Manrope-Regular', 40px, 1.6rem);
    }

    .text-36 {
        @include text('Manrope-Regular', 50px, 2rem);
    }
}