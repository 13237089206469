.shimmer {
  background: linear-gradient(to right, #dad4d4 8%, #b8b9b9 18%, #969797 33%);
  background-size: 1000px 100%;
  animation: shimmer 3.2s linear infinite forwards;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
