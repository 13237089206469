.shimmer-table-row {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 25px 0;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 20px;

  &--header {
    background-color: transparent;
    box-shadow: none;
    margin: 0;
  }

  > .shimmer {
    margin: 0 30px;
  }
}

.shimmer-table-col {
  width: 100%;
  height: 10px;
  border-radius: 10px;
}
