body {
  overflow-y: hidden;
}

.master-content {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .page-content {
    position: relative;
    height: 100%;
    max-height: calc(100vh - $header-height - $footer-height);

    section {
      height: 100%;
    }

    .qrcode {
      padding-top: 30px;
      max-width: 200px;
      height: auto;
      margin: 0 auto;
      width: 100%;
    }
  }
}

.scrollbar-container.ps {
  height: 65vh;
  padding-right: 25px;
  padding-bottom: 25px;
  position: relative;

  &:after {
    content: "";
    background: linear-gradient(180deg,
        rgba(240, 247, 255, 0) -32.07%,
        #f5faff 50.34%);
    bottom: -25px;
    display: block;
    height: 10%;
    left: 0;
    position: sticky;
    width: 98%;
  }

  .ps__rail-y {
    opacity: 1;
    /*width: 8.5px;
        background: linear-gradient(rgb(243, 249, 255) 0%, rgba(243, 249, 255, 0.11) 54.69%, rgba(243, 249, 255, 0) 76.56%);
        box-shadow: rgb(255 255 255 / 90%) -2px -2px 4px inset, rgb(131 131 131 / 40%) 2px 2px 4px inset;
        border-radius: 25px; */

    .ps__thumb-y {
      background: $light-ice-background;
      border-radius: 50px;
      width: 8.5px;
      right: 0px;
    }
  }

  .ps__rail-x {
    opacity: 1;

    .ps__thumb-x {
      background: $light-ice-background;
      border-radius: 50px;
      height: 8.5px;
      right: 0px;
    }
  }

  .ps__rail-x:hover,
  .ps__rail-y:hover,
  .ps__rail-x:focus,
  .ps__rail-y:focus,
  .ps__rail-x.ps--clicking,
  .ps__rail-y.ps--clicking {
    opacity: 1;
    background: inherit;
  }
}

.full-page-scrollbar {
  &.scrollbar-container.ps {
    height: 100%;
    padding: 0;

    &:after {
      content: none;
    }
  }
}

.separator {
  position: absolute;
  width: 100%;
  height: 4.5px;
  background: $light-blue;
  box-shadow: $shadow-separator;
  border-radius: 3px;
}

.sub-separator {
  height: 4px;
  background: $light-blue;
  box-shadow: $shadow-separator;
  border-radius: 3px;
}

.sub-separator {
  height: 4px;
  background: $light-blue;
  box-shadow: $shadow-separator;
  border-radius: 3px;
}

.sub-separator-vertical {
  @extend .sub-separator;
  width: 5px;
  height: 44vh;
  margin: auto;
}

.two-columns-layout {
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--light-background);

  .half-col {
    width: 50%;
    position: relative;

    &.left-col {
      padding-top: 15vh;
      padding-left: 17vw;

      @media screen and (max-width: 1512px) {
        padding-left: 11vw;
      }

      @media screen and (max-width: 1367px) {
        padding-top: 10vh;
      }

      @media screen and (max-width: 1280px),
      screen and (max-height: 850px) {
        padding-top: 8vh;
      }

      .text-16,
      .text-36 {
        max-width: 450px;
      }
    }

    &.right-col {
      padding-top: 15vh;
      padding-right: 17vw;

      @media screen and (max-width: 1512px) {
        padding-right: 11vw;
      }

      @media screen and (max-width: 1367px) {
        padding-top: 10vh;
      }

      @media screen and (max-width: 1280px),
      screen and (max-height: 850px) {
        padding-top: 8vh;
      }

      &>* {
        margin-left: auto;
      }
    }

    &.align-x-center {
      padding-left: 0;
      padding-right: 0;
    }

    &.align-block-center {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(.margin-auto)>* {
        margin-left: 0;
      }
    }

    &.flow-column {
      flex-flow: column;
    }

    &.no-padding-top {
      padding-top: 0;
    }
  }
}

//INPUTS

.input-wrapper {
  position: relative;
  width: 470px;

  &:not(.consent, .check_box, .toggle-switch) {
    &:not(:last-child) {
      margin-bottom: 1.688rem;
    }

    input {
      @extend .text-16;
      width: 100%;
      background: $light-blue;
      box-shadow: $shadow-2;
      -webkit-box-shadow: $shadow-2;
      border-radius: 100px;
      padding: 10px 20px;
      outline: none;
      color: $black06;
      margin-top: 5px;
      text-overflow: ellipsis;

      &:-webkit-autofill,
      &:-internal-autofill-selected {
        box-shadow: $shadow-2;
        -webkit-box-shadow: $shadow-2;
        background: $light-blue !important;
      }

      &:focus~label,
      &:focus~#box-year,
      &:not([value=""])~#box-year,
      &:not([value=""])~label,
      &:-webkit-autofill~#box-year,
      &:-webkit-autofill~label {
        @extend .text-14;
        top: -6px;
        left: 20px;
        transform: none;
        background: $light-blue;
      }

      &:not(:read-only):focus~label,
      &:not(:read-only):focus~#box-year {
        color: $blue;
        @extend .bold;
      }
    }

    label {
      cursor: text;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      color: $black06;
      transition: all 0.15s ease-in-out 0s;
    }

    #box-year {
      top: 40%;
      cursor: text;
      position: absolute;
      left: 20px;
      transform: translateY(-50%);
      color: $black06;
      transition: all 0.15s ease-in-out 0s;
    }
  }

  .list-group {
    margin-bottom: 50px;

    &>* {
      width: 380px;
      height: 48px;
      display: flex;
      border-radius: 100px;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      background: $light-blue;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &:hover:not(:disabled, .active) {
        background: $light-blue2;
      }

      &.active {
        background: var(--blue-gradient);
        box-shadow: $shadow-3;
        color: $white;
        @extend .bold;
      }
    }
  }

  .content-list-group {
    margin-bottom: 5px;

    &>* {
      // width: 280px;
      // height: 90px;
      display: flex;
      box-sizing: border-box;
      background: white;

      // padding: 10px 5px;
      // opacity: 1;
      &.content-item {
        // height: 80px;
      }

      .content-img {
        position: relative;
        width: 100px;
        height: 100px;
        overflow: hidden;

        img {
          width: auto;
          height: inherit;
          right: 0;
          position: absolute;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &.card {
        background: $light-blue2;
        box-shadow: 0px 0px 4px;
        padding: 3px;
      }


      &:hover {
        background: $light-blue2;
        opacity: 1;
      }

      .card-body {
        padding: 5px 10px;
      }
    }
  }

  .show-hide-psw {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
  }

  .edit-field {
    position: absolute;
    right: 12px;
    top: 55%;
    transform: translateY(-50%);
    background: $light-blue;
    box-shadow: $shadow-1;
    border-radius: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
  }

  .valid-field-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.consent,
  &.check_box {
    display: flex;
    align-items: center;

    input {
      background: var(--light-background);
      box-shadow: $shadow-1;
      border-radius: 100px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      width: 39px;
      height: 39px;
      cursor: pointer;
      margin-right: 16px;
      position: relative;
      flex-shrink: 0;

      &:checked::after {
        content: "";
        display: block;
        background: url("../img/tick.svg") no-repeat;
        background-position: center;
        background-size: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scaleX(-1);
        width: 20px;
        height: 20px;
      }
    }

    label {
      @extend .text-14;
    }
  }

  &.toggle-switch {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    input[type="checkbox"] {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 57px;
      height: 30px;
      background: $light-blue;
      box-shadow: $shadow-2;
      display: block;
      border-radius: 100px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        background: $light-blue;
        box-shadow: $shadow-1;
        border-radius: 100px;
        transition: 0.3s;
      }
    }

    input:checked+label {
      background: var(--blue-gradient);
      box-shadow: $shadow-5;
    }

    input:checked+label:after {
      left: 100%;
      right: 0;
      transform: translateX(-100%);
    }
  }
}

.dob-box-wrapper {
  grid-column-gap: 18px;
}

.two-inputs {
  display: flex;
  justify-content: space-between;

  .input-wrapper {
    width: 48%;
  }
}

//BUTTONS

.standard-btn {
  width: 236px;
  // background: $light-blue;
  padding: 17.5px 0;
  border-radius: 100px;
  box-shadow: $shadow-1;
  cursor: pointer;
  background: #62cdc5;

  p {
    color: #fff;
  }

  &.slot-time-btn {
    background: $light-blue;

    p {
      color: #000;
    }
  }

  &:disabled {
    cursor: initial;
    box-shadow: $shadow-button;
    opacity: 0.8;
  }

  // &:hover:not(:disabled, .active) {
  //   background: $light-blue2;
  // }

  &.active {
    background: var(--blue-gradient);
    box-shadow: $shadow-3;
    color: $white;
    @extend .bold;


    .card-text {
      color: #fff
    }
  }
}

.small-btn {
  background: var(--light-background);
  box-shadow: $shadow-1;
  border-radius: 125px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    cursor: initial;
    box-shadow: $shadow-button;
    opacity: 0.8;
  }

  &:hover:not(:disabled) {
    background: $light-blue2;
  }

  &.sunken {
    box-shadow: $shadow-2;
  }
}

.extra-small-btn {
  @extend .small-btn;
  width: 40px;
  height: 40px;
}

.go-back-button {
  @extend .small-btn;
  margin-top: -30px;
  margin-bottom: 30px;
}

//MODAL

.modal-backdrop {
  background: $black1;
  opacity: 0.6;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  top: 0;
  left: 0;
}

.modal-wrapper {
  position: absolute;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--light-background);
  border-radius: 30px;
  padding: 30px 36px;

  .modal-title {
    @extend .text-18;
    @extend .bold;
  }

  .sub-separator {
    margin: 15px 0;
  }
}

//offcanvas

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background: var(--light-background);
  background-clip: padding-box;
  outline: 0;
  box-shadow: $shadow-2;
  transition: transform 0.3s ease-in-out;
}

.offcanvas-header {
  // display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;

  button {
    float: right;
  }
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 30vh;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 30vh;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 75%;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);

  &.payment {
    height: 100%;
  }

  &.faq-content {
    height: 100%;
  }
}

.floating-btn {
  position: absolute;
  bottom: 20px;
  right: 20%;
  left: 20%;

  // z-index: 101;
  button {
    background: #62cdc5;

    p {
      color: #fff;
    }
  }
}

.floating-btn-sticky {
  position: sticky !important;
}

.offcanvas.show {
  transform: none;
  visibility: visible;
}

.chat-con-label {
  padding: 15px;
  border: 1px solid #62cdc5;
  border-radius: 25% 25% 25% 0%;

  &.selected {
    background: $light-grey;
  }
}

.faq-content {
  .ask-btn {
    display: block;
  }
}

.faq-box {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .faq-list-item {
    position: relative;
  }
}

//SHIMMER

.shimmer {
  color: grey;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 1.7s;
  animation-iteration-count: 8;
  font-size: 50px;
  max-width: 200px;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.shimmer-logo {
  color: grey;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s;
  animation-iteration-count: 2;
  font-size: 50px;
  max-width: 200px;
}

@keyframes shimmer-logo {
  100% {
    -webkit-mask-position: left;
  }
}

.common-shimmer {
  color: grey;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 1.7s;
  animation-iteration-count: 15;
}

@keyframes common-shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.wide-shimmer-wrapper {
  margin: auto;
  width: 650px;
}

//PROGRESS BAR

.progress-bar-wrapper {
  background: $light-blue;
  box-shadow: $shadow-separator;
  border-radius: 100px;
  width: 30.2vw;
  margin-bottom: 30px;

  .progress-bar {
    background: var(--blue-gradient);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    transition: width 0.6s ease;
    white-space: nowrap;
    height: 100%;
  }
}

.pw-requirments {
  width: 271px;
  margin: auto;
}

.login-form-container {
  .logo-login {
    margin-bottom: 5rem;
  }
}

.chat-wrapper {
  position: absolute;
  bottom: 30px;
  right: 40px;
  display: flex;
  justify-content: space-between;

  .chatbot {
    padding: 30px;
    margin-bottom: 30px;

    .chatbot-btn {
      position: absolute;
      right: 0px;
      bottom: 0;

      &.hidden {
        display: none;
      }
    }

    .chatbot-chat-container {
      background: $light-blue;
      border-radius: 25px;
      box-shadow: $shadow-1;
      width: 340px;
      height: 50vh;
      display: flex;
      flex-direction: column;

      .chat-header {
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        padding: 10px 20px;
        background: var(--blue-gradient);
        display: flex;
        justify-content: space-between;
      }

      .chat-body {
        height: 100%;
        position: relative;
        overflow-y: hidden;

        .chat-conversation {
          padding: 20px;
          height: calc(100% - 40px);
          overflow-y: scroll;

          .chat-message-item {
            display: flex;

            &:first-child {
              margin-bottom: 15px;
            }

            &:not(:first-child, :last-child) {
              margin: 15px 0;
            }

            &:last-child {
              margin-top: 15px;
            }

            .chat-text {
              padding: 15px;
              border-radius: 12px;
              max-width: 85%;

              &>* {
                @extend .text-14;
              }
            }

            &.bot {
              .chat-text {
                background: var(--blue-gradient);
                color: $white;

                &>* {
                  color: $white;
                }
              }
            }

            &.user {
              justify-content: flex-end;

              .chat-text {
                background-color: $light-blue2;
              }
            }

            &.bot-typing {
              .chat-text {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                box-shadow: 0 10px 17px 0 rgba(0, 0, 0, 0.1);

                .typing-dots {
                  width: 10px;
                  height: 10px;
                  background-color: $blue;
                  border-radius: 50%;
                  display: flex;
                  margin: 0 2px;
                  animation: loadingFade 1s infinite;
                  animation-delay: 0;

                  &:nth-child(2) {
                    opacity: 0.8;
                    animation-delay: 0.2s;
                  }

                  &:nth-child(3) {
                    opacity: 0.4;
                    animation-delay: 0.4s;
                  }
                }

                @keyframes loadingFade {
                  0% {
                    opacity: 0;
                  }

                  50% {
                    opacity: 0.8;
                  }

                  100% {
                    opacity: 0;
                  }
                }
              }
            }
          }
        }

        .chat-input {
          box-shadow: $shadow-button;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 20px;
          border-bottom-right-radius: 25px;
          border-bottom-left-radius: 25px;
          background-color: $light-blue2;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 40px;

          input {
            outline: none;
            width: 100%;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .azure-bot {
    height: 94%;
    margin-top: 30px;

    .chatbot-btn {
      position: absolute;
      right: 0px;
      bottom: 0;

      &.hidden {
        display: none;
      }
    }

    .chatbot-chat-container {
      background: $light-blue;
      border-radius: 25px;
      box-shadow: $shadow-1;
      width: 340px;
      height: 100%;
      display: flex;
      flex-direction: column;

      .chat-header {
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        padding: 10px 20px;
        background: #081B38;
        display: flex;
        justify-content: space-between;
      }

      .chat-body {
        height: 100%;
        position: relative;
        overflow-y: hidden;

        .web-chat {
          width: 100%;
          height: 98%;
        }

        .chat-conversation {
          padding: 20px;
          height: calc(100% - 40px);
          overflow-y: scroll;

          .chat-message-item {
            display: flex;

            &:first-child {
              margin-bottom: 15px;
            }

            &:not(:first-child, :last-child) {
              margin: 15px 0;
            }

            &:last-child {
              margin-top: 15px;
            }

            .chat-text {
              padding: 15px;
              border-radius: 12px;
              max-width: 85%;

              &>* {
                @extend .text-14;
              }
            }

            &.bot {
              .chat-text {
                background: var(--blue-gradient);
                color: $white;

                &>* {
                  color: $white;
                }
              }
            }

            &.user {
              justify-content: flex-end;

              .chat-text {
                background-color: $light-blue2;
              }
            }

            &.bot-typing {
              .chat-text {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                box-shadow: 0 10px 17px 0 rgba(0, 0, 0, 0.1);

                .typing-dots {
                  width: 10px;
                  height: 10px;
                  background-color: $blue;
                  border-radius: 50%;
                  display: flex;
                  margin: 0 2px;
                  animation: loadingFade 1s infinite;
                  animation-delay: 0;

                  &:nth-child(2) {
                    opacity: 0.8;
                    animation-delay: 0.2s;
                  }

                  &:nth-child(3) {
                    opacity: 0.4;
                    animation-delay: 0.4s;
                  }
                }

                @keyframes loadingFade {
                  0% {
                    opacity: 0;
                  }

                  50% {
                    opacity: 0.8;
                  }

                  100% {
                    opacity: 0;
                  }
                }
              }
            }
          }
        }

        .chat-input {
          box-shadow: $shadow-button;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 20px;
          border-bottom-right-radius: 25px;
          border-bottom-left-radius: 25px;
          background-color: $light-blue2;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 40px;

          input {
            outline: none;
            width: 100%;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .dflow-chatbot {
    padding: 30px;
    margin-bottom: 30px;

    .chatbot-btn {
      position: absolute;
      right: 50px;
      bottom: 0;

      &.hidden {
        display: none;
      }
    }

    .chatbot-chat-container {
      background: $light-blue;
      border-radius: 25px;
      box-shadow: $shadow-1;
      width: 340px;
      height: 50vh;
      display: flex;
      flex-direction: column;

      .chat-header {
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        padding: 10px 20px;
        background: var(--blue-gradient);
        display: flex;
        justify-content: space-between;
      }

      .chat-body {
        height: 100%;
        position: relative;
        overflow-y: hidden;

        .chat-conversation {
          padding: 20px;
          height: calc(100% - 40px);
          overflow-y: scroll;

          .chat-message-item {
            display: flex;

            &:first-child {
              margin-bottom: 15px;
            }

            &:not(:first-child, :last-child) {
              margin: 15px 0;
            }

            &:last-child {
              margin-top: 15px;
            }

            .chat-text {
              padding: 15px;
              border-radius: 12px;
              max-width: 85%;

              &>* {
                @extend .text-14;
              }
            }

            &.bot {
              .chat-text {
                background: var(--blue-gradient);
                color: $white;

                &>* {
                  color: $white;
                }
              }
            }

            &.user {
              justify-content: flex-end;

              .chat-text {
                background-color: $light-blue2;
              }
            }

            &.bot-typing {
              .chat-text {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                box-shadow: 0 10px 17px 0 rgba(0, 0, 0, 0.1);

                .typing-dots {
                  width: 10px;
                  height: 10px;
                  background-color: $blue;
                  border-radius: 50%;
                  display: flex;
                  margin: 0 2px;
                  animation: loadingFade 1s infinite;
                  animation-delay: 0;

                  &:nth-child(2) {
                    opacity: 0.8;
                    animation-delay: 0.2s;
                  }

                  &:nth-child(3) {
                    opacity: 0.4;
                    animation-delay: 0.4s;
                  }
                }

                @keyframes loadingFade {
                  0% {
                    opacity: 0;
                  }

                  50% {
                    opacity: 0.8;
                  }

                  100% {
                    opacity: 0;
                  }
                }
              }
            }
          }
        }

        .chat-input {
          box-shadow: $shadow-button;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 20px;
          border-bottom-right-radius: 25px;
          border-bottom-left-radius: 25px;
          background-color: $light-blue2;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 40px;

          input {
            outline: none;
            width: 100%;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .dflow-ms {
    padding: 30px;
    margin-bottom: 30px;

    button#widgetIcon {
      position: absolute;
      right: 140px;
      bottom: 50px;
    }
  }

  .azflow-chatbot {
    padding: 30px;
    margin-bottom: 30px;

    .chatbot-btn {
      position: absolute;
      right: 100px;
      bottom: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .chat-wrapper {
    z-index: 9;

    .azure-bot {
      &.bot-component.bot-show {
        height: calc(100% - 20px);
        margin-top: 0px;
        position: fixed;
        right: 0px;
        bottom: 20px;
        width: 100%;

        .chatbot-chat-container {
          background: $light-blue;
          border-radius: 25px;
          box-shadow: $shadow-1;
          width: 100%;
          height: 97%;
          display: flex;
          flex-direction: column;

          /* .chat-header {
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      padding: 10px 20px;
      background: var(--blue-gradient);
      display: flex;
      justify-content: space-between;
    }

    .chat-body {
      height: 100%;
      position: relative;
      overflow-y: hidden;
      #webchat {
        width: 100%;
        height: 98%;
      }

      .chat-conversation {
        padding: 20px;
        height: calc(100% - 40px);
        overflow-y: scroll;

        .chat-message-item {
          display: flex;

          &:first-child {
            margin-bottom: 15px;
          }

          &:not(:first-child, :last-child) {
            margin: 15px 0;
          }

          &:last-child {
            margin-top: 15px;
          }

          .chat-text {
            padding: 15px;
            border-radius: 12px;
            max-width: 85%;

            & > * {
              @extend .text-14;
            }
          }

          &.bot {
            .chat-text {
              background: var(--blue-gradient);
              color: $white;

              & > * {
                color: $white;
              }
            }
          }

          &.user {
            justify-content: flex-end;

            .chat-text {
              background-color: $light-blue2;
            }
          }

          &.bot-typing {
            .chat-text {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px;
              box-shadow: 0 10px 17px 0 rgba(0, 0, 0, 0.1);

              .typing-dots {
                width: 10px;
                height: 10px;
                background-color: $blue;
                border-radius: 50%;
                display: flex;
                margin: 0 2px;
                animation: loadingFade 1s infinite;
                animation-delay: 0;

                &:nth-child(2) {
                  opacity: 0.8;
                  animation-delay: 0.2s;
                }

                &:nth-child(3) {
                  opacity: 0.4;
                  animation-delay: 0.4s;
                }
              }

              @keyframes loadingFade {
                0% {
                  opacity: 0;
                }

                50% {
                  opacity: 0.8;
                }

                100% {
                  opacity: 0;
                }
              }
            }
          }
        }
      }

      .chat-input {
        box-shadow: $shadow-button;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 20px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        background-color: $light-blue2;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 40px;

        input {
          outline: none;
          width: 100%;
          margin-right: 5px;
        }
      }
    } */
        }
      }
    }
  }
}

.order-item {
  width: 380px;
  background: var(--light-background);
  box-shadow: $shadow-2;
  border-radius: 20px;
  padding: 20px;

  .order-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .order-item-icon {
      width: 45px;
      height: 45px;
      background: $light-blue;
      box-shadow: $shadow-2;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .order-item-body {
    margin-top: 10px;

    .order-item-number {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .order-item-schedule {
    display: flex;
    align-items: center;

    .schedule-date {
      background: var(--blue-gradient);
      box-shadow: $shadow-4;
      border-radius: 15px;
      width: 70px;
      height: 70px;
      margin-right: 15px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &>* {
        display: block;
        line-height: 20px !important;
      }
    }
  }
}

.test-mode-choice-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.bio-list-wrapper {
  width: 380px;

  .bio-list-item-wrapper {
    margin-bottom: 15px;
  }

  .bio-list-item {
    background: var(--light-background);
    box-shadow: $shadow-1;
    border-radius: 20px;
    padding: 20px 23px;
    width: 100%;
    height: 65px;
  }

  .scrollbar-container.ps {
    height: 48vh;
  }

  .item-openend {
    .bio-list-item {
      box-shadow: $shadow-2;
    }
  }
}

.report-list-wrapper {
  .report-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 26.5px;
  }
}

.scheduling-slots-wrapper {
  width: 380px;

  .scheduling-slots-header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .scheduling-slots-available {
    margin-bottom: 50px;

    &>* {
      width: 380px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &.save-card {
        padding: 5px;
        justify-content: space-evenly;
      }
    }
  }

  &~.btn-wrapper {
    width: 380px;
    margin-left: auto;
  }
}

.order-checkout-wrapper,
.lab-order {
  width: 376px;
  margin-bottom: 50px;
  margin-left: auto;

  &~.btn-wrapper {
    width: 376px;
    margin-left: auto;
  }
}

.stripe-payment-wrapper {
  width: 481px;
  padding: 50px 30px;
  background: var(--light-background);
  box-shadow: $shadow-2;
  border-radius: 30px;

  .stripe-payment-inner {
    position: relative;
  }
}

.feedback-section {
  .feedback-image {
    height: 75px;
    width: 75px;
  }

  .input-feedback {
    margin-bottom: 60px;
  }

  p.drag-text {
    margin-top: 15px;
    margin-bottom: 40px;

    &.gray {
      color: #c0c0c0;
    }

    &.very-unlikely {
      color: #ff0077;
    }

    &.unlikely {
      color: #fb355b;
    }

    &.probably-not {
      color: #f9554b;
    }

    &.maybe-not {
      color: #f67d37;
    }

    &.rather-no {
      color: #f19f00;
    }

    &.maybe {
      color: #d9af24;
    }

    &.rather-yes {
      color: #9bba2f;
    }

    &.yes {
      color: #77c036;
    }

    &.likely {
      color: #55c53c;
    }

    &.very-likely {
      color: #2ec057;
    }

    &.yellow {
      color: #f19f00;
    }

    &.red {
      color: #ff0077;
    }

    &.green {
      color: #55c53c;
    }
  }

  .rc-slider-handle,
  .rc-slider-handle.rc-slider-handle-dragging {
    background: var(--light-background);
    box-shadow: $shadow-1;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    border: none;
    margin: 0;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    opacity: 1;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 13px;
      height: 13px;
      border-radius: 50%;
    }

    &[aria-valuenow="1"] {
      &::after {
        background-color: #ff0077;
      }
    }

    &[aria-valuenow="2"] {
      &::after {
        background-color: #fb355b;
      }
    }

    &[aria-valuenow="3"] {
      &::after {
        background-color: #f9554b;
      }
    }

    &[aria-valuenow="4"] {
      &::after {
        background-color: #f67d37;
      }
    }

    &[aria-valuenow="5"] {
      &::after {
        background-color: #f19f00;
      }
    }

    &[aria-valuenow="6"] {
      &::after {
        background-color: #d9af24;
      }
    }

    &[aria-valuenow="7"] {
      &::after {
        background-color: #9bba2f;
      }
    }

    &[aria-valuenow="8"] {
      &::after {
        background-color: #77c036;
      }
    }

    &[aria-valuenow="9"] {
      &::after {
        background-color: #77c036;
      }
    }

    &[aria-valuenow="10"] {
      &::after {
        background-color: #2ec057;
      }
    }
  }

  .gray {

    .rc-slider-handle,
    .rc-slider-handle.rc-slider-handle-dragging {
      &[aria-valuenow="1"] {
        &::after {
          background-color: #c0c0c0;
        }
      }
    }
  }

  .rc-slider {
    margin-bottom: 10px;
    width: 260px;
    margin: 0 auto;
  }

  .rc-slider-track,
  .rc-slider-rail {
    background: var(--light-background);
    box-shadow: $shadow-separator;
    border-radius: 3px;
  }

  .rc-slider-step {
    display: none;
  }

  .rc-slider-mark {
    .rc-slider-mark-text {
      @extend .text-14;
      color: $grey2;
      width: 15px;
      vertical-align: bottom;
      margin-top: 12px;

      &[data-active="1"] {
        @extend .text-18;
        @extend .bold;
        color: $black;
        margin-top: 10px;
      }
    }
  }

  textarea.form-control {
    background: linear-gradient(90deg, #f1f8ff 2%, #f5faff 100%);

    box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
      0px -1px 4px rgba(169, 166, 169, 0.2),
      inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 3px #c7daff;
    border-radius: 20px;
    width: 380px;
    height: 200px;
    resize: none;
    border: none;
    margin-bottom: 110px;
    padding: 10px 20px;

    &:focus-visible {
      outline: none;
    }
  }
}

.biomarker-result-wrapper {
  max-width: 450px;
}

.profile-area {
  .mobile-only {
    display: none;
  }

  .profile {
    width: 35%;
    padding-top: 20vh;
    padding-left: 17vw;
    padding-right: 74px;

    @media screen and (max-width: 1512px) {
      padding-left: 11vw;
    }

    @media screen and (max-width: 1512px) {
      padding-top: 15vh;
    }

    li {
      margin-bottom: 20px;
      margin-top: 20px;

      &:last-child {
        margin-top: 40px;
      }

      .profile-icon {
        margin-right: 30px;
      }

      a {
        display: flex;
        align-items: center;
      }

      &.selected {
        background: linear-gradient(94.94deg, #a347ff 0%, #432cd2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .user-right-content {
    width: 65%;
    padding-top: 17vh;
    padding-right: 17vw;

    @media screen and (max-width: 1512px) {
      padding-right: 11vw;
    }

    @media screen and (max-width: 1512px) {
      padding-top: 12vh;
    }

    @media screen and (max-width: 1280px) {
      padding-top: 9vh;
    }

    .order-item {
      margin: auto;
      margin-bottom: 2rem;
    }

    .personal-info-wrapper {
      max-width: 480px;
      margin-left: auto;
    }

    .payment-info-wrapper {
      max-width: 580px;
      margin-left: auto;

      .card-wrapper {
        margin-top: 35px;

        .card-icon-number-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .card-managing-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 25px 0;
        }
      }
    }

    .settings-wrapper {
      max-width: 480px;
      margin: 0 auto;
    }
  }
}

/*Spinner*/
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;

  .spinner {
    height: 100%;
    width: 100%;
    border: 4px solid $light-grey;
    border-top: 4px solid $blue;
    border-radius: 50%;
    animation: spinner 0.7s linear infinite;
    justify-content: center;
  }
}

.logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.middle-logo {
  display: block;
  margin-left: auto;
  top: 48%;
  margin-right: auto;
  position: absolute;
  left: 22%;
  right: 22%;
  width: 200px;
}

.pending-order-list {
  background: var(--light-background);
  padding-top: 8vh;
  padding-left: 17vw;

  @media screen and (max-width: 1512px) {
    padding-left: 11vw;
  }

  @media screen and (max-width: 1367px) {
    padding-top: 6vh;
  }

  .multiple-order-items {
    display: flex;
    margin-top: 50px;

    .order-item {
      flex-shrink: 0;
    }

    .order-item:not(:last-child) {
      margin-right: 40px;
    }
  }

  .scrollbar-container {
    height: auto;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background: linear-gradient(94.94deg, #a347ff 0%, #432cd2 100%);
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
    0px -1px 2px rgba(169, 166, 169, 0.2),
    inset -2px 2px 4px rgba(24, 4, 33, 0.2),
    inset 2px 2px 5px rgba(109, 75, 151, 0.9);
  border-radius: 100px;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.appointment-instructions-section {
  .instructions-wrapper {
    width: 31vw;
    margin-left: auto;
  }
}

.order-pdf-section {
  display: flex;
  flex-direction: column;

  .pdf-viewer-object {
    width: 95%;
    height: calc(95vh - 75px);
    align-self: center;
  }
}

.payment-container {
  max-width: 400px;

  .input-wrapper {
    width: auto;
  }
}

ul {
  &.lab-items-list {
    list-style: disc;
  }
}

//FIX FOR SAFARI OLD VERSIONS
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .profile-area .profile li.selected {
      color: $blue;
      background: none !important;
      -webkit-background-clip: unset !important;
      -webkit-text-fill-color: initial !important;
      background-clip: unset !important;
      text-fill-color: initial !important;
    }
  }
}

@media screen and (max-width: 900px) {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: $light-ice-background;
    border-radius: 100px;
  }

  .shimmer {
    max-width: none;
  }

  .master-content {
    height: var(--app-height);

    .page-content {
      max-height: calc(var(--app-height) - $mobile-header-height - $footer-height );
      overflow-y: auto;
      background: var(--light-background);
      padding-bottom: 20px;
    }
  }

  .two-columns-layout {
    flex-direction: column;
    padding: 0 35px;

    .half-col {
      width: 100%;
      padding: 0 !important;

      &.background-lilla {
        background: var(--light-background);
      }

      &.align-block-center {
        &:not(.margin-auto)>* {
          width: 100%;
        }
      }

      &.right-col {
        padding-bottom: 30px !important;
      }
    }
  }

  .input-wrapper {
    width: 100%;

    &:not(.consent, .check_box, .toggle-switch) {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &.toggle-switch {
      margin-right: 10px;
    }
  }

  .scrollbar-container.ps {
    height: auto;
    padding: 0;
  }

  .modal-wrapper {
    width: 90%;
  }

  .progress-bar-wrapper,
  .width-500,
  .width-480 {
    width: 100%;
  }

  .go-back-button {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .login-section {
    .login-form-container {
      padding-top: 30px;

      .logo-login {
        display: none;
      }
    }
  }

  .terms-conditions-section {
    .right-clinical-inner {
      padding-top: 25px;

      >*:not(:first-child) {
        text-align: center;
      }
    }
  }

  .order-item {
    width: 100%;

    .order-item-schedule {
      .schedule-date {
        width: 90px;
      }
    }
  }

  .order-instructions-wrapper {
    text-align: center;
  }

  .appointment-instructions-section {
    .instructions-wrapper {
      width: 100%;
      margin-left: 0;
    }
  }

  .test-mode-choice-wrapper {
    justify-content: center;

    .standard-btn {
      margin-bottom: 20px;
    }
  }

  .bio-list-wrapper,
  .order-checkout-wrapper,
  .stripe-payment-wrapper {
    width: 100%;
  }

  .stripe-payment-wrapper {
    padding: 30px;
  }

  .how-to-get-tested-wrapper {
    text-align: center;
  }

  .schedule-appointment-section {
    .order-item {
      display: none;
    }
  }

  .scheduling-slots-wrapper {
    width: 100%;

    .scheduling-slots-available>*,
    &~.btn-wrapper {
      width: 100%;
    }

    .slot-date {
      @include text("Manrope-Regular", 25px, 1.125rem);
    }
  }

  .order-checkout-wrapper,
  .lab-order {
    width: 100%;

    &~.btn-wrapper {
      width: 100%;
    }
  }

  .lab-order {
    margin-top: 20px;

    h2 {
      display: none;
    }
  }

  .card-payment-wrapper {
    .sub-separator-vertical {
      display: none;
    }
  }

  .pending-order-list {
    padding: 0 35px;

    .multiple-order-items {
      display: block;
      margin-top: 30px;

      .order-item:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .orders-area-section {
    .orders-wrapper {
      margin-top: 30px;
    }

    .sub-separator-vertical {
      display: none;
    }

    .bio-list-wrapper {
      margin-top: 25px;

      .scrollbar-container.ps {
        height: auto;
      }
    }
  }

  .two-columns-layout .half-col.left-col .text-36,
  .biomarker-result-wrapper {
    max-width: none;
  }

  .main-meter-container {
    transform: scale(0.75) translate(-65.5%, -62.5%);
    top: 50%;
    left: 50%;
    position: absolute;
  }

  .biomarker-details-section {
    .width-500 {
      width: 100%;
    }
  }

  .range-input-container {
    margin-bottom: 50px;
    width: 100%;
    height: 150px;
    position: relative;
  }

  .profile-area {
    .mobile-only {
      display: flex;
    }

    .user-right-content,
    .profile {
      width: 100%;
      padding: 0;

      &.background-lilla {
        background: var(--light-background);
      }
    }

    .profile {
      margin-top: 35px;
      text-align: center;
      height: 100%;

      li {
        margin-top: 15px;
        margin-bottom: 15px;

        a {
          justify-content: space-between;
        }
      }

      .profile-usermenu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 88%;
      }

      .go-back-button {
        margin-bottom: 0;
        transform: rotate(180deg);
        width: 39px;
        height: 39px;
      }
    }

    .user-right-content {
      .section-title {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        justify-items: self-start;
        align-items: center;
        margin-bottom: 40px;

        .go-back-button {
          margin-bottom: 0;
        }
      }
    }

    .menu-opened {
      .user-right-content {
        display: none;
      }
    }

    .menu-closed {
      .profile {
        display: none;
      }
    }

    .personal-info-wrapper {
      .two-inputs {
        display: block;

        .input-wrapper {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }

    .payment-info-wrapper {
      .card-icon-number-wrapper {
        .input-wrapper {
          margin-left: 20px;
        }
      }
    }

    .change_password_profile {
      text-align: center;
    }
  }

  .feedback-section {
    .left-content {
      .text-32 {
        @include text("Manrope-Regular", 25px, 1.25rem);
      }
    }

    .input-feedback {
      margin-top: 30px;
    }

    .standard-btn {
      margin-top: 35px;
    }

    .text-area-feedback {
      padding: 0 !important;

      textarea.form-control {
        margin-top: 30px;
        margin-bottom: 0;
        width: 250px;
      }
    }

    .skip-btn {
      margin-top: 20px;
    }
  }
}

.show-only-mobile {
  display: none !important;
}

.hide-only-mobile {
  display: block !important;
}

@media only screen and (max-width: 600px) {
  .show-only-mobile {
    display: block !important;
  }

  .hide-only-mobile {
    display: none !important;
  }
}

.init-logo {
  top: 35%;
  left: 40%;
  position: absolute;
}

.zoom-in-out-box {
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}